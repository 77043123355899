import { Component, OnInit, ViewContainerRef } from '@angular/core'
import { NzModalService } from 'ng-zorro-antd/modal'
import { QueryTable } from 'src/app/services/interface/general.model'
import { NzTableQueryParams } from 'ng-zorro-antd/table'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzButtonSize } from 'ng-zorro-antd/button'
import { LeaveManagementService } from 'src/app/services/leave-management/leave-management.service'
import { ViewDetailLeaveComponent } from '../view-detail-leave/view-detail-leave.component'
import { AnnualLeaveType } from 'src/app/services/interface/leave-management/leave.model'

@Component({
  selector: 'app-tab-waiting-leave-approval',
  templateUrl: './tab-waiting-leave-approval.component.html',
  //   styleUrls: ['./annual-leave.component.scss'],
})
export class TabWaitingLeaveApprovalComponent implements OnInit {
  size: NzButtonSize = 'large'
  selectedFilter = 'WAITING'

  listOfData: AnnualLeaveType[] = []

  isLoading: boolean = true
  totalData: number = 0
  pageSize: number = 5
  pageIndex: number = 1

  form: FormGroup
  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private leaveManagementService: LeaveManagementService,
    private fb: FormBuilder,
    private notification: NzNotificationService,
  ) {
    this.form = this.fb.group({
      keyword: [null, Validators.compose([Validators.required])],
      prefix: ['vendor', Validators.compose([Validators.required])],
    })
  }

  queryTable: QueryTable = {
    sort: { field: null, order: null },
    filters: [],
    pageSize: this.pageSize,
    pageIndex: this.pageIndex - 1,
  }

  listOfColumns = [
    {
      name: 'Actions',
      key: 'actions',
      sortOrder: null,
      sortFn: null,
      listOfFilter: [],
      filterFn: null,
      left: true,
      right: false,
      width: '40px',
    },
    {
      name: 'Leave Type',
      key: 'type',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '50px',
    },
    {
      name: 'Total Leave',
      key: 'total_leave',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '50px',
    },
    {
      name: 'Date of Leave',
      key: 'date',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '50px',
    },
    {
      name: 'Position',
      key: 'position_name',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '50px',
    },
    {
      name: 'Notes',
      key: 'notes_approval',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '50px',
    },
  ]

  ngOnInit(): void {
    this.fetchListApprovalHr()
  }

  fetchListApprovalHr() {
    this.isLoading = true
    this.leaveManagementService
      .getListApprovalLeave(this.queryTable, this.selectedFilter)
      .subscribe((r: any) => {
        this.isLoading = false
        this.listOfData = r.rows
        this.totalData = r.count
      })
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort, filter } = params
    this.queryTable.pageSize = pageSize
    this.queryTable.pageIndex = pageIndex - 1
    const currentSort = sort.find(item => item.value !== null)
    const sortField = (currentSort && currentSort.key) || null
    const sortOrder = (currentSort && currentSort.value) || null

    if (sortField != null && sortOrder != null) {
      this.queryTable.sort = {
        field: sortField,
        order: sortOrder,
      }
    } else {
      this.queryTable.sort = { field: null, order: null }
    }
    this.fetchListApprovalHr()
  }

  deleteLeave(id: string) {
    const modal = this.modal.confirm({
      nzTitle: 'Are you sure reject this leave?',
      nzContent: '<b style="color: red;">This action cannot be undo</b>',
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => {
        // this.leaveManagementService.giveCancelRequestLeave(id).subscribe(
        //   r => {
        //     this.notification.success('Success', 'Successfully reject leave!')
        //     this.fetchListApprovalHr()
        //   },
        //   err => {
        //     this.notification.error('Error', err.error.message)
        //     this.fetchListApprovalHr()
        //   },
        // )
      },
      nzCancelText: 'No',
      nzOnCancel: () => '',
    })
  }

  approveLeave(id: string) {
    const modal = this.modal.confirm({
      nzTitle: 'Are you sure approve this leave?',
      nzContent: '<b style="color: red;">This action cannot be undo</b>',
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => {
        // this.leaveManagementService.giveApproveRequestLeave(id).subscribe(
        //   r => {
        //     this.notification.success('Success', 'Successfully approve leave!')
        //     this.fetchListApprovalHr()
        //   },
        //   err => {
        //     this.notification.error('Error', err.error.message)
        //     this.fetchListApprovalHr()
        //   },
        // )
      },
      nzCancelText: 'No',
      nzOnCancel: () => '',
    })
  }

  viewDetailLeave(leaveId: string) {
    const modal = this.modal.create({
      nzTitle: 'View Detail Special Leave',
      nzContent: ViewDetailLeaveComponent,
      nzStyle: { top: '50px' },
      nzViewContainerRef: this.viewContainerRef,
      nzComponentParams: {
        leaveId: leaveId,
        filterStatus: this.selectedFilter,
      },

      nzMaskClosable: false,
      // nzFooter: [
      //   {
      //     label: 'Close',
      //     danger: true,
      //     onClick() {
      //       modal.destroy()
      //     },
      //   },
      // ],
      nzWidth: '75%',
    })
    modal.afterClose.subscribe(() => this.fetchListApprovalHr())
  }
}
