import { Component, OnInit, ViewContainerRef } from '@angular/core'
import { NzModalService } from 'ng-zorro-antd/modal'
import { QueryTable } from 'src/app/services/interface/general.model'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzButtonSize } from 'ng-zorro-antd/button'
import { LeaveManagementService } from 'src/app/services/leave-management/leave-management.service'
import { AnnualLeaveType } from 'src/app/services/interface/leave-management/leave.model'
import { NzUploadFile } from 'ng-zorro-antd/upload'
import { format } from 'date-fns'
import { error } from 'console'

@Component({
  selector: 'app-leave-approval-hr',
  templateUrl: './leave-approval-hr.component.html',
  styleUrls: ['./leave-approval-hr.component.scss'],
})
export class LeaveApprovalHrComponent implements OnInit {
  size: NzButtonSize = 'large'
  status = ''

  listOfData: AnnualLeaveType[] = []

  isLoading: boolean = true
  totalData: number = 0
  pageSize: number = 5
  pageIndex: number = 1

  modeDownload = null
  dateDownload = null

  form: FormGroup
  constructor(
    private modal: NzModalService,
    private leaveManagementService: LeaveManagementService,
    private fb: FormBuilder,
  ) {
    this.form = this.fb.group({
      keyword: [null, Validators.compose([Validators.required])],
      prefix: ['vendor', Validators.compose([Validators.required])],
      date: [''],
      mode: [''],
    })
  }

  queryTable: QueryTable = {
    sort: { field: null, order: null },
    filters: [],
    pageSize: this.pageSize,
    pageIndex: this.pageIndex - 1,
  }

  listOfColumns = [
    {
      name: 'Actions',
      key: 'actions',
      sortOrder: null,
      sortFn: null,
      listOfFilter: [],
      filterFn: null,
      left: true,
      right: false,
      width: '40px',
    },
    {
      name: 'Leave Type',
      key: 'leaveType',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '50px',
    },
    {
      name: 'Total Leave',
      key: 'totalLeave',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '50px',
    },
    {
      name: 'Date of Leave',
      key: 'dateOfLeave',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '50px',
    },
    {
      name: 'Position',
      key: 'position',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '50px',
    },
    {
      name: 'Notes',
      key: 'notes_approval',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '50px',
    },
  ]

  ngOnInit(): void {
    this.fetchListApprovalHr()
  }

  fetchListApprovalHr() {
    this.isLoading = true
    this.leaveManagementService
      .getListApprovalLeave(this.queryTable, 'WAITING')
      .subscribe((r: any) => {
        this.isLoading = false
        this.listOfData = r.rows
        this.totalData = r.count
      })
  }

  onQueryParamsChange(params): void {
    const { pageSize, pageIndex, sort, filter } = params
    this.queryTable.pageSize = pageSize
    this.queryTable.pageIndex = pageIndex - 1
    const currentSort = sort.find(item => item.value !== null)
    const sortField = (currentSort && currentSort.key) || null
    const sortOrder = (currentSort && currentSort.value) || null

    if (sortField != null && sortOrder != null) {
      this.queryTable.sort = {
        field: sortField,
        order: sortOrder,
      }
    } else {
      this.queryTable.sort = { field: null, order: null }
    }
    this.fetchListApprovalHr()
  }

  search() {
    if (this.form.value.keyword || this.form.value.keyword?.length > 0) {
      this.queryTable.filters = [
        {
          field: this.form.value.prefix,
          keyword: this.form.value.keyword,
        },
      ]
    } else {
      this.queryTable.filters = []
    }
    this.fetchListApprovalHr()
  }

  changePrefix(event) {
    if (event == 'status') {
      this.form.patchValue({ keyword: true })
    } else {
      this.form.patchValue({ keyword: null })
    }
  }

  reset() {
    this.form.reset()
    this.form.patchValue({ prefix: 'name' })
    this.queryTable.sort = null
    this.queryTable.filters = []
    this.listOfColumns.forEach(r => {
      r.sortOrder = null
    })
    this.fetchListApprovalHr()
  }

  trackByName(_: number, item): string {
    return item.name
  }

  handleDownload = () => {
    let dateFormat = format(this.dateDownload, 'yyyy-MM-dd')

    // if (this.modeDownload && this.dateDownload) {
    this.leaveManagementService
      .exportApprovalLeave(this.modeDownload, dateFormat)
      .subscribe((r: any) => {
        this.isLoading = false

        window.open(r.link + '?s=download', '_blank')
      }),
      error => {
        this.isLoading = false
      }
    // }
  }

  onChange(result: Date): void {
    this.dateDownload = result
  }
  onChangeMode(result) {
    this.modeDownload = result
  }
}
