import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { QueryTable } from 'src/app/services/interface/general.model'
import { NzTableQueryParams, NzTableFilterList, NzTableSortOrder } from 'ng-zorro-antd/table'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import * as Reducers from 'src/app/store/reducers'
import { HrService } from 'src/app/services/hr.service'
import { Attendance } from 'src/app/services/interface/attendance.model'
import { differenceInCalendarDays } from 'date-fns'
import { ShiftService } from 'src/app/services/shift.service'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
// import { AddEmployeeComponent } from './add-employee/add-employee.component'
// import { EditEmployeeComponent } from './edit-employee/edit-employee.component'

interface ColumnItem {
  name: string
  key: string | null
  sortOrder: NzTableSortOrder | null
  sortFn: null | boolean
  listOfFilter: NzTableFilterList
  filterFn: null
  left: boolean
  right: boolean
  width: number
}

@Component({
  selector: 'app-normal-attendance',
  templateUrl: './normal-attendance.component.html',
  styleUrls: ['./normal-attendance.component.scss'],
})
export class AttendanceNormalComponent implements OnInit {
  listOfData: Attendance[] = []
  shiftList: any = []

  isVisible = false
  isLoading: boolean = true
  previewVisible = false
  late: boolean
  early: boolean
  prefixCategoryshift: boolean = false
  prefixCategoryStatus: boolean = false
  defaultprefixCategory: boolean = true

  totalData: number = 0
  pageSize: number = 25
  pageIndex: number = 1

  previewImage: string

  form: FormGroup

  today = new Date()

  @ViewChild('downloadLink') downloadLink: ElementRef

  constructor(
    private hrService: HrService,
    private shiftService: ShiftService,
    private fb: FormBuilder,
    private store: Store<any>,
  ) {
    this.form = this.fb.group({
      keyword: [null, Validators.compose([Validators.required])],
      prefix: ['vendor', Validators.compose([Validators.required])],
    })
    this.store.pipe(select(Reducers.getUser)).subscribe(state => {
      this.role = state.roleCode
      // console.log(this.role)
    })
  }
  date = new Date()
  dateMonth = null
  exportDate = Date.toString()
  prefixDefault = 'employeeName'
  role: string

  queryTable: QueryTable = {
    sort: null,
    filters: [{ field: 'createdAt', keyword: this.formatDate(this.date) }],
    pageSize: this.pageSize,
    pageIndex: this.pageIndex - 1,
  }

  disabledDate = (current: Date): boolean => {
    return differenceInCalendarDays(current, this.today) > 0
  }

  listOfColumns: ColumnItem[] = [
    {
      name: 'Employee Code',
      key: 'employeeCode',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: 100,
    },
    {
      name: 'Employee Name',
      key: 'employeeName',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: 100,
    },
    {
      name: 'Shift',
      key: 'shift',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: 100,
    },
    {
      name: 'Time In',
      key: 'createdAt',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: 100,
    },
    {
      name: 'Location In',
      key: 'locationIn',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: 100,
    },
    {
      name: 'Picture In',
      key: 'pictureIn',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: 180,
    },
    {
      name: 'Status In',
      key: 'statusIn',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: 100,
    },
    {
      name: 'Offsite Reason In',
      key: 'offsiteReasonIn',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: 100,
    },
    {
      name: 'Time Out',
      key: 'updatedAt',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: 100,
    },
    {
      name: 'Location Out',
      key: 'locationOut',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: 100,
    },
    {
      name: 'Picture Out',
      key: 'pictureOut',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: 180,
    },
    {
      name: 'Status Out',
      key: 'statusOut',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: 100,
    },
    {
      name: 'Offsite Reason Out',
      key: 'offsiteReasonOut',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: 100,
    },
  ]

  ngOnInit(): void {
    this.fetchAttendance()
    this.shiftDropdownList()
  }

  formatDate(date: Date): string {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  shiftDropdownList() {
    this.shiftService.filterShiftDropdown().subscribe({
      next: (res: any) => {
        this.shiftList = res
      },
      error: (err: any) => {},
    })
  }

  prefixSearchCategory(value: any) {
    if (value == 'shift') {
      this.defaultprefixCategory = false
      this.prefixCategoryshift = true
      this.prefixCategoryStatus = false
    } else if (value == 'statusIn' || value == 'statusOut') {
      this.defaultprefixCategory = false
      this.prefixCategoryshift = false
      this.prefixCategoryStatus = true
    } else {
      this.defaultprefixCategory = true
      this.prefixCategoryshift = false
      this.prefixCategoryStatus = false
    }
  }

  fetchAttendance() {
    this.isLoading = true
    this.hrService.getAttendance(this.queryTable).subscribe((r: any) => {
      // console.log(r)
      this.isLoading = false
      this.listOfData = r.rows
      this.totalData = r.count
      this.late = r.rows.late
      this.early = r.rows.early
      for (let i = 0; i < this.listOfColumns.length; i++) {
        if (this.listOfColumns[i].key === null) {
          this.listOfColumns[i].key = '-'
        }
      }
    })
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    // console.log(params)
    // console.log(this.pageIndex, this.pageSize)
    const { pageSize, pageIndex, sort } = params
    this.queryTable.pageSize = pageSize
    this.queryTable.pageIndex = pageIndex - 1
    const currentSort = sort.find(item => item.value !== null)
    const sortField = (currentSort && currentSort.key) || null
    const sortOrder = (currentSort && currentSort.value) || null

    if (sortField != null && sortOrder != null) {
      this.queryTable.sort = {
        field: sortField,
        order: sortOrder,
      }
    } else {
      this.queryTable.sort = null
    }
    this.fetchAttendance()
  }

  search() {
    if (this.form.value.keyword?.length > 0) {
      this.queryTable.filters = [
        {
          field: this.form.value.prefix,
          keyword: this.form.value.keyword,
        },
      ]
    } else {
      this.queryTable.filters = []
    }
    this.queryTable.pageIndex = 0
    // console.log(this.queryTable)
    this.fetchAttendance()
  }

  reset() {
    this.form.reset()
    this.form.patchValue({ prefix: 'vendor' })
    this.queryTable.sort = null
    this.queryTable.filters = []
    this.listOfColumns.forEach(r => {
      r.sortOrder = null
    })
    this.fetchAttendance()
  }

  trackByName(_: number, item: ColumnItem): string {
    return item.name
  }

  deleteEmployee() {}

  openImage(filename: string) {
    // console.log(filename)
    this.previewImage = filename
    this.previewVisible = true
  }

  onChange(result: Date): void {
    var isExist = false
    // console.log(isExist)
    for (let i = 0; i < this.queryTable.filters.length; i++) {
      if (this.queryTable.filters[i].field == 'createdAt') {
        isExist = true
        this.queryTable.filters[i].keyword = this.formatDate(result)
      }
    }
    if (!isExist) {
      this.queryTable.filters.push({
        field: 'createdAt',
        keyword: this.formatDate(result),
      })
    }
    this.fetchAttendance()
  }

  onChangeMonth(): void {
    // console.log('QYQYQYQYQYQYQYQYQYQYYQYQYQYQYQYQY')
    // console.log(testing)
  }

  onExport() {
    // console.log('BEFORE DOWNLOAD')
    this.hrService
      .exportAttendance(this.formatDate(this.date))
      .subscribe((r: { message: string; link: string }) => {
        // console.log(r)
        // console.log(r.link)
        window.open(r['link'], '_blank')
      })
    // console.log('DOWNLOAD')
  }

  onExportByMonth() {
    // console.log('BEFORE DOWNLOAD')
    // console.log(this.dateMonth.valueOf())
    this.hrService
      .exportAttendanceByMonth(this.formatDate(this.dateMonth))
      .subscribe((r: { message: string; link: string }) => {
        // console.log(r)
        // console.log(r.link)
        // console.log(r.link)
        window.open(r['link'], '_blank')
      })
    // console.log('DOWNLOAD')
    this.isVisible = false
  }

  showModal(): void {
    this.isVisible = true
  }

  handleCancel(): void {
    console.log('Button cancel clicked!')
    this.isVisible = false
  }
}
